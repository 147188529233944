"use client";
import React from "react";
import Image from "next/image";
import Link from "next/link";

import footerStyles from "./footer.module.css";

import footerIllustration from "../../public/images/footer-illustration-2.png";
import logo from "../../public/images/blue.svg";
import facebook from "../../public/images/facebook.svg";
import instagram from "../../public/images/instagram.svg";
import appStoreBadgeNo from "../../public/images/app-store-badge.svg";
import appStoreBadgeDk from "../../public/images/dk-app-store-badge.svg";
import appStoreBadgeSe from "../../public/images/se-app-store-badge.svg";
import googlePlayBadgeNo from "../../public/images/google-play-badge.svg";
import googlePlayBadgeDk from "../../public/images/dk-google-play-badge.svg";
import googlePlayBadgeSe from "../../public/images/se-google-play-badge.svg";
import DownloadLink from "./Downloadlink";
import { openConsentModal } from "@/tracking";
import { FooterData, SITE } from "@/types";

type FooterProps = {
  footerData?: FooterData;
  site: SITE;
};

const downloadLinkConfigs = {
  "www.hyre.no": {
    googlePlayBadge: googlePlayBadgeNo,
    googlePlayAltText: "Tilgjengelig på Google Play",
    appStoreBadge: appStoreBadgeNo,
    appStoreAltText: "Last ned fra App Store",
  },
  "www.hyre.se": {
    googlePlayBadge: googlePlayBadgeSe,
    googlePlayAltText: "Ladda ned på Google Play",
    appStoreBadge: appStoreBadgeSe,
    appStoreAltText: "Hamta fra App Store",
  },
  "www.hyre.dk": {
    googlePlayBadge: googlePlayBadgeDk,
    googlePlayAltText: "Nu på Google Play",
    appStoreBadge: appStoreBadgeDk,
    appStoreAltText: "Download fra App Store",
  },
};

const Address = ({ site }: { site: SITE }) => {
  if (site === "www.hyre.dk") {
    return (
      <>
        Hyre ApS
        <br />
        tlf. +45 52 51 26 99 |&nbsp;
        <a
          href="mailto:hej@hyre.dk"
          style={{
            color: "#8490a3",
          }}
        >
          hej@hyre.dk
        </a>
        <br />
        Danneskiold-Samsøes Allé 41, 1434 København
        <br />
        CVR-nummer: 42991481
      </>
    );
  }
  if (site === "www.hyre.se") {
    return (
      <>
        Hyre AB
        <br />
        org. nr 559370-4645
        <br />
        tel: 077-575 76 00 |&nbsp;
        <a
          href="mailto:hej@hyre.se"
          style={{
            color: "#8490a3",
          }}
        >
          e-post: hej@hyre.se
        </a>
      </>
    );
  }
  return (
    <>
      Hyre AS
      <br />
      tlf. 22 44 49 73 |&nbsp;
      <a
        href="mailto:hei@hyre.no"
        style={{
          color: "#8490a3",
        }}
      >
        hei@hyre.no
      </a>
      <br />
      Chr. Krohgs gate 16, <br />
      0186 Oslo
      <br />
    </>
  );
};

const Footer = ({ footerData, site }: FooterProps) => {
  const downloadLinkConfig =
    downloadLinkConfigs[site] || downloadLinkConfigs["www.hyre.no"];
  return (
    <div
      style={{
        background: "white",
        position: "relative",
        marginTop: "128px",
      }}
    >
      <div className={`${footerStyles.footer} container`}>
        <div className="row" style={{ marginBottom: "10px" }}>
          <div className="col">
            <Image
              src={footerIllustration}
              alt=""
              loading="lazy"
              style={{
                width: "100%",
                height: "auto",
                marginBottom: "2rem",
              }}
            />
          </div>
        </div>
        <div className="row justify-content-between">
          <div className="col-12 col-md-2">
            <Image
              src={logo}
              width="87"
              alt="Hyre"
              style={{ marginBottom: "2rem" }}
            />
            <p
              style={{
                fontSize: 14,
                color: "#8490a3",
              }}
            >
              <Address site={site} />
            </p>
          </div>
          {footerData?.categories.map((category) => (
            <div key={category.title} className="pt-3 col-12 col-md-2">
              {footerData.show_category_titles && <h6>{category.title}</h6>}
              <ul className={`${footerStyles.nav} nav`}>
                {category.links.map((link, i) => (
                  <li key={i} className="nav-item">
                    {link.action == "SHOW_CONSENT_MODAL" ? (
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          openConsentModal();
                        }}
                      >
                        {link.title}
                      </a>
                    ) : (
                      <Link href={link.page?.url || link.url}>
                        {link.title || link.page?.title}
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ))}
          <div className="col-lg-2 col-12">
            <div className="row">
              <div className={`col-md-8 col-12 ${footerStyles.downloadBadges}`}>
                <DownloadLink>
                  <Image
                    src={downloadLinkConfig.appStoreBadge}
                    alt={downloadLinkConfig.appStoreAltText}
                    style={{ height: 40, width: "auto" }}
                    className="mx-1 mx-md-0"
                  />
                </DownloadLink>
                <DownloadLink>
                  <Image
                    src={downloadLinkConfig.googlePlayBadge}
                    alt={downloadLinkConfig.googlePlayAltText}
                    style={{ height: 40, width: "auto" }}
                    className="mx-1 mx-md-0"
                  />
                </DownloadLink>
              </div>
              <div className="d-flex justify-content-center col-md-4 col-12">
                <a href="https://www.instagram.com/drivehyre/">
                  <Image
                    src={instagram}
                    className={footerStyles.socialIcon}
                    alt="Instagram"
                  />
                </a>
                <a href="https://www.facebook.com/DriveHyre">
                  <Image
                    src={facebook}
                    className={footerStyles.socialIcon}
                    alt="Facebook"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
