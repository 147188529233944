"use client";
import { Locale, Page, PageDetails } from "@/types";
import Image from "next/image";
import Link from "next/link";
import logo from "../../public/images/logo-white.png";
import BedriftLink from "./B2BLink";
import styles from "./navbar.module.css";
import { useState } from "react";
import { usePathname } from "next/navigation";

const Translations = {
  en: {
    home: "Home",
  },
  no: {
    home: "Hjem",
  },
  se: {
    home: "Hem",
  },
  dk: {
    home: "Hjem",
  },
} as const;

export const Navbar = ({
  locale,
  pages,
  currentPage,
}: {
  currentPage?: PageDetails;
  locale: Locale;
  pages: Page[];
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const items: { title: string; link: string; hideOnMobile: boolean }[] = [];

  const translations = Translations[locale];

  const path = usePathname();

  let filteredPages = pages
    .filter((p) => p.meta.show_in_menus)
    // Sort by "path" variable, which is how sorting is defined by the CMS
    .sort((a, b) => (a.meta.path > b.meta.path ? 1 : -1));

  const isBedrift = currentPage?.meta.url.startsWith("/bedrift/");

  // Special menu rules for bedrift
  if (isBedrift) {
    const pathFilter = pages.find((p) => p.meta.slug === "bedrift")?.meta.path;
    filteredPages = filteredPages
      // Only show pages that are in the bedrift tree
      .filter((page) => !pathFilter || page.meta.path.startsWith(pathFilter))
      // Only show level 4 pages, which is the level below "bedrift"
      .filter((page) => page.meta.path.length === 4 * 4);
  } else {
    // Only show level 3 pages, which is the top level for a site
    filteredPages = filteredPages.filter(
      (page) => page.meta.path.length === 3 * 4
    );
  }

  filteredPages.forEach((page) => {
    items.push({
      title: page.title,
      link: page.meta.url,
      // Only "bedrift" shown on mobile
      hideOnMobile: page.meta.slug !== "bedrift",
    });
  });

  const businessLinkCountry =
    locale === "se" ? "SWE" : locale === "dk" ? "DNK" : "NOR";

  return (
    <div>
      <nav
        style={{ background: "#1c5dff" }}
        className="d-flex flex-column navbar navbar-expand-md navbar-light fixed-top"
      >
        <div className="container">
          <div className="navbar-brand d-flex">
            <Link href="/">
              <Image
                className="d-flex"
                src={logo}
                style={{ height: 18, margin: 0, width: "auto" }}
                alt="Hyre"
              />
            </Link>
            {isBedrift && (
              <Link href="/bedrift">
                <div
                  style={{
                    textAlign: "center",
                    color: "white",
                    marginLeft: 10,
                    fontSize: 20,
                    height: 16,
                  }}
                >
                  for bedrift
                </div>
              </Link>
            )}
          </div>
          <ul
            style={{ right: 16 }}
            className="ms-auto position-absolute-on-mobile navbar-nav"
          >
            {items.map((item, i) => (
              <li
                className={`nav-item ${
                  item.hideOnMobile ? "hide-on-mobile" : ""
                }`}
                key={i}
              >
                <Link
                  href={item.link}
                  style={{
                    textDecoration: path === item.link ? "underline" : "none",
                  }}
                >
                  {item.title}
                </Link>
              </li>
            ))}
            {isBedrift && (
              <li className="nav-item">
                <BedriftLink
                  country={businessLinkCountry}
                  style={{
                    display: "inline-block",
                    fontWeight: "bold",
                  }}
                >
                  Logg inn
                </BedriftLink>
              </li>
            )}
          </ul>
          <div
            className={styles["mobile-nav"] + " " + (isOpen ? styles.open : "")}
          >
            <div className={styles.inner}>
              <div
                className={styles["toggle-icon"]}
                onClick={() => setIsOpen((old) => !old)}
              >
                <span className={styles.one} />
                <span className={styles.two} />
                <span className={styles.three} />
              </div>

              <Link
                href="/"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                {translations.home}
              </Link>
              {isBedrift && <Link href="/bedrift">Bedrift</Link>}
              {items.map((item, i) => (
                <Link key={i} href={item.link}>
                  {item.title}
                </Link>
              ))}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};
